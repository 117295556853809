<template>
  <sm-editable-item
    v-model="form"
    class="telemarketing-item"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('DomaAiIntegration')"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import editableListItemEdit from '@/mixins/editableListItemEdit.js';

import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'DomaAiIntegrationEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'DomaAiIntegration',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Интеграция с "Дома АИ"',
          route: { name: 'DomaAiIntegration' },
        },
        {
          text: 'Редактирование интеграции с "Дома АИ"',
        },
      ],
      pageHeader: 'Редактирование интеграции с "Дома АИ"',
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      sites: (state) => state.common.sites,
    }),

    fields() {
      const sites = this.sites.data;

      return [
        {
          tab: '',
          form: [
            {
              type: 'text',
              key: 'inn',
              label: 'ИНН',
            },
            {
              type: 'select',
              key: 'databaseName',
              label: 'База данных',
              list: sites,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const item = this.getItem({
      name: this.controllerName,
      id: this.id,
    });
    const sites = this.getCommonList({ name: 'Sites' });

    Promise.all([item, sites])
      .then(() => {
        this.form = this.lodash.cloneDeep(this.item.data);
        this.validateForm();
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
    }),

    onSaveForm() {
      if (this.form.databaseName) {
        const dbName =
          this.sites.data.find((site) => site.id === this.form.databaseName)
            ?.name || '';

        this.form = { ...this.form, databaseName: dbName };
      }

      this.onSave('DomaAiIntegration');
    },

    validateForm() {
      if (!this.form.databaseName) return;

      console.log(this.form.databaseName);
      const dbId =
        this.sites.data.find((site) => site.name === this.form.databaseName)
          ?.id || null;

      this.form = { ...this.form, databaseName: dbId };
    },
  },
};
</script>
